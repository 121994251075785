<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">分账审核</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
					<el-form-item>
						<el-input  :maxlength="50" style="width:300px;" v-model="seachForm.searchInfo" placeholder="请输入供应商名称/支付交易号/订单编号" clearable></el-input>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-select  v-model="seachForm.orderCheckStatus" clearable placeholder="请选择状态">
							<el-option v-for="item in auditOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(seachForm)">搜索</el-button>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="sumbitMore()">批量审核</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" ref="elTable" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
                <el-table-column type="selection" width="55" :selectable='checkboxSelect' ></el-table-column>
				<el-table-column prop="checkStartTime" label="提交时间" ></el-table-column>
				<el-table-column prop="orderSerialNo" label="支付交易号" ></el-table-column>
				<el-table-column prop="orderNo" label="订单编号" ></el-table-column>
				<el-table-column prop="platformYingjsjf" label="平台应结算积分" ></el-table-column>
				<el-table-column prop="supplierName" label="供应商名称" ></el-table-column>
				<el-table-column prop="supplierYingjsjf" label="供应商应结算积分" ></el-table-column>
				<el-table-column prop="orderCheckStatus" label="状态" >
                    <template slot-scope="scope">
                        <span >{{scope.row.orderCheckStatus == 1 ? '待审核' : scope.row.orderCheckStatus == 2 ? '审核通过' : '审核拒绝'}}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="checkStartTime" label="审核时间" ></el-table-column>
				<el-table-column prop="checkBy" label="审核人" ></el-table-column>
				<el-table-column fixed="right" label="操作">
					<template style="display: flex;" slot-scope="scope">
						<el-button :disabled="scope.row.orderCheckStatus != 1" @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
							审核
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增的弹窗 -->
		<el-dialog :visible.sync="dialogVisible" width="600px" :before-close="handleClose" title="审核">
			<el-form class="form_box" :model="sizeForm" label-position='right'  ref="sizeForm" label-width="150px" size="small">
                <el-form-item label="未结算积分：">
                    <span>{{Number(sizeForm.supplierYingjsjf ) + Number(sizeForm.platformYingjsjf) }}</span>
				</el-form-item>
                <el-form-item label="供应商名称：">
                    <span>{{sizeForm.supplierName }}</span>
				</el-form-item>
                <el-form-item label="平台应结算积分：">
                    <span>{{sizeForm.platformYingjsjf}}</span>
				</el-form-item>
                <el-form-item label="供应商应结算积分：">
                    <span>{{sizeForm.supplierYingjsjf}}</span>
				</el-form-item>
                <el-form-item label="状态：">
					<el-radio-group v-model="checkStatus">
						<el-radio label="2">审核通过</el-radio>
						<el-radio label="3">审核拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item size="large">
                    <el-button @click.native.prevent="handleClose('sizeForm')">取消</el-button>
					<el-button type="primary"  @click.native.prevent="submitForm('sizeForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

        <!-- 新增的弹窗 -->
		<el-dialog :visible.sync="moreAudit" width="600px" :before-close="handleClose" title="批量审核">
			<el-form class="form_box" :model="sizeForm" label-position='right'  ref="sizeForm" label-width="150px" size="small">
                <h3 style="text-align:center;">此操作为批量审核操作，请谨慎使用</h3>
                <el-form-item label="状态：" style="margin:30px 0;">
					<el-radio-group v-model="checkStatus">
						<el-radio label="2">审核通过</el-radio>
						<el-radio label="3">审核拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item size="large">
                    <el-button @click.native.prevent="handleClose('sizeForm')">取消</el-button>
					<el-button type="primary"  @click.native.prevent="submitFormMore()">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		splitaccountAuditList,splitaccountAuditSplit 
	} from "@/api/platform/index";
	export default {
		name: "splitaccountAuditList",
		data() {
			return {
                checkStatus:'2',
                seachForm:{
					orderCheckStatus: '',
					searchInfo: '',
				},
                sizeForm:{
                    id: '',
                    supplierYingjsjf:'',
                    platformYingjsjf:'',
                    supplierName:''
                },
				auditOption: [{label: '全部',value: ''},{label: '待审核',value: 1},{label: '审核通过',value: 2},{label: '审核拒绝',value: 3}],
				dialogVisible: false,
				data: [],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
                moreAudit:false
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				splitaccountAuditList({
                    orderCheckStatus: this.seachForm.orderCheckStatus,
                    searchInfo:this.seachForm.searchInfo,
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
            
            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},
            
            // 审核完的状态不能选
            checkboxSelect (row, rowIndex) {
                if (row.orderCheckStatus == 1) {
                    return true // 不禁用
                }else{
                    return false // 不禁用
                }
            },

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

			//编辑提交
			submitForm(){
                let that = this;
				if(that.dialogVisible){
                    splitaccountAuditSplit({
                        ids: that.sizeForm.id,
                        operator: that.checkStatus == '2' ? 'allow' : 'refuse',
                    }).then(res=>{
                        if (res.code == 200) {
                            that.$message.success('审核成功');
                            that.sizeForm = {
                                id: '',
                                platformYingjsjf: '',
                                supplierYingjsjf: '',
                                supplierName:''
                            }
                            that.dialogVisible = false
                            that.moreAudit = false;
                            that.checkStatus = '2';
                            that.page = 1
                            that.list();
                        } else {
                            that.$message.error(res.msg);
                        }
                    }).catch(err => {
                        that.$message.error(err.msg);
                    })
                }
			},
			
			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					id: '',
					supplierYingjsjf:'',
					platformYingjsjf:'',
					supplierName:''
				}
				this.dialogVisible = false;
				this.moreAudit = false;
				this.checkStatus = '2';
			},

			//编辑按钮点击
			updataclick(i,tab){
				this.sizeForm = {
                    id: tab[i].id,
                    supplierName: tab[i].supplierName,
                    supplierYingjsjf: tab[i].supplierYingjsjf,
                    platformYingjsjf: tab[i].platformYingjsjf,
				}
				this.id = tab[i].id
				this.dialogVisible = true
			},

            // 批量审核弹出层
            sumbitMore(){
                let that = this;
                if(that.$refs.elTable.selection.length > 0){
                    that.moreAudit = true;
                }else{
                    that.$message.warning('请先选择要审核的内容');
                }
                return;
            },

			// 批量审核
            submitFormMore(){
                let that = this;
                var arrIds = [];
                for(var i of that.$refs.elTable.selection){
                    arrIds.push(i.id);
                }
                splitaccountAuditSplit({
                    ids: arrIds.join(','),
                    operator: that.checkStatus == '2' ? 'allow' : 'refuse',
                }).then(res=>{
                    if (res.code == 200) {
                        that.$message.success('审核成功');
                        that.sizeForm = {
                            id: '',
                            platformYingjsjf: '',
                            supplierYingjsjf: '',
                            supplierName:''
                        }
                        that.dialogVisible = false
                        that.moreAudit = false;
                        that.checkStatus = '2';
                        that.page = 1
                        that.list();
                    } else {
                        that.$message.error(res.msg);
                    }
                }).catch(err => {
                    that.$message.error(err.msg);
                })
            }
		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:150px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
</style>
